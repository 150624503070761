<template>
  <div>
  </div>
</template>

<script>
    export default {
        data(){
            return {
                projectId: 1,
                baseUrl: "https://open.weixin.qq.com/connect/oauth2/authorize",
                callBack: "",
                url: ""
            }
        },
        created() {
            this.projectId = localStorage.getItem("roleKey")
            let parseUrl = this.parseUrl()
            if( parseUrl.code ) {
                this.getOpenId(parseUrl.code)
            } else {
                this.callBack = this.url + "#/auth"
                this.getCode()
            }
        },
        methods:{
            getCode(){
                this.$http.get("/payment/wxpay/getAppId/"+this.projectId).then(res=>{
                    if( res ) {
                        let authUrl = this.baseUrl+"?appid="+res+"&redirect_uri="+encodeURIComponent(this.callBack)+"&response_type=code&scope=snsapi_base&state=123#wechat_redirect"
                        window.location.href = authUrl
                    }
                })
            },
            getOpenId(code){
                let params = {
                    roleId: this.projectId,
                    code: code,
                    state: 123,
                    grant_type: "authorization_code"
                }
                this.$http.get("/payment/wxpay/getOpenId", { params: params }).then(res=>{
                    if(res) {
                        localStorage.setItem("openId", res)
                        window.location.href = window.origin + window.location.pathname + "#/order"
                    }
                })
            },
            parseUrl(){
                let url   = window.location.href
                let query = url.split('#')[0]
                let params= query.split('?')
                if( params[1] ) {
                    let arr   = params[1].split('&')
                    let result={}
                    for(let i=0;i<arr.length;i++){
                        result[arr[i].split('=')[0]] = arr[i].split('=')[1]
                    }
                    return result
                } else {
                    this.url = params[0]
                    return {}
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
